import request from '@/utils/request'

// 招标列表
export function tenderListApi(query) {
    return request({
        url: '/bidding/tender/list',
        method: 'get',
        params: query
    })
}

//
export function noticeListApi(query) {
    return request({
        url: '/bidding/notice/list',
        method: 'get',
        params: query
    })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: '/system/dict/data/list',
        method: 'get',
        params: {pageNum: 1, pageSize: 999, dictType}
    })
}


// 查询招标公告详细
export function getNotice(uid) {
    return request({
        url: '/bidding/notice/' + uid,
        method: 'get'
    })
}


// 查询招标项目详细
export function getTender(sid) {
    return request({
        url: '/bidding/tender/' + sid,
        method: 'get'
    })
}
